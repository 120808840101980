import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const getValidationForNumberField = () => {
  const minMessage =
    "This field is required. Please enter an amount or 0 if none applies.";

  return yup.lazy((value) =>
    value === ""
      ? yup.string().when("isDependantStudent", {
          is: (value) => value !== "No",
          then: () => yup.string().required(minMessage),
          otherwise: () => yup.string().notRequired(),
        })
      : yup.number().when("isDependantStudent", {
          is: (value) => value !== "No",
          then: () =>
            yup
              .number()
              .typeError()
              .max(999000000, "Value can't be more than $999,000,000")
              .required(minMessage),
          otherwise: () => yup.number().notRequired(),
        })
  );
};

export const BASIC_QUESTIONS_VALIDATION_SCHEMA = yupResolver(
  yup.object({
    firstName: yup
      .string()
      .max(64, "First name must be less than or equal to 64 letters")
      .required("Please provide your first name"),
    lastName: yup
      .string()
      .max(64, "Last name must be less than or equal to 64 letters")
      .required("Please provide your last name"),
    dateOfBirth: yup
      .string()
      .required("Please provide your date of birth")
      .matches(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(192[5-9]|19[3-9][0-9]|200[0-9]|2010)$/,
        "Please provide a valid date between 1925 and 2010"
      ),
    email: yup
      .string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Must be a valid email address"
      )
      .required("Email address is required"),
    stateOfResidency: yup
      .string()
      .required("Please provide the State of Residency"),
    fafsaEligibility: yup.string().required("This question is required"),
    isDependantStudent: yup.string().required("This question is required"),
    parentEmail: yup.string().when("isDependantStudent", {
      is: (value) => value === "Yes" || value === "",
      then: () =>
        yup
          .string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            "Must be a valid email address"
          )
          .required("Parent's email address is required"),
      otherwise: () => yup.string().notRequired(),
    }),
    isMarried: yup.string().required("This question is required"),
    otherDependants: yup.string().required("This question is required"),
    householdPeople: yup
      .number()
      .typeError("Please provide the amount of people")
      .max(99, "Amount of household people must be less than or equal to 99")
      .required("Please provide the amount of people"),
    //dependant questions start
    parentMaritalStatus: yup.string().when("isDependantStudent", {
      is: (value) => value !== "No",
      then: () => yup.string().required("This question is required"),
      otherwise: () => yup.string().notRequired(),
    }),
    parentTaxFillingStatus: yup.string().when("isDependantStudent", {
      is: (value) => value !== "No",
      then: () => yup.string().required("This question is required"),
      otherwise: () => yup.string().notRequired(),
    }),
    parentAdjustedGrossIncome: getValidationForNumberField(),
    parentIncomeFromWork: getValidationForNumberField(),
    parentDeductiblePaymentsToIRA: getValidationForNumberField(),
    parentUntaxedPortionsOfIRA: getValidationForNumberField(),
    parentUntaxedPortionsOfPensions: getValidationForNumberField(),
    parentGrantOrScholarshipAid: getValidationForNumberField(),
    parentEducationCredits: getValidationForNumberField(),
    parentUSIncomeTax: getValidationForNumberField(),
    childSupportAidRecievedByParent: getValidationForNumberField(),
    parentSavingsAndCheckings: getValidationForNumberField(),
    parentInvestmentsNetWorth: getValidationForNumberField(),
    parentBusinessesValue: getValidationForNumberField(),
    //dependant questions end
    taxFillingStatus: yup.string().when("isDependantStudent", {
      is: (value) => value === "No",
      then: () => yup.string().required("This question is required"),
      otherwise: () => yup.string().notRequired(),
    }),
    AGI: getValidationForNumberField(),
    incomeFromWork: getValidationForNumberField(),
    paymentsToIRA: getValidationForNumberField(),
    untaxedPortionsOfIRA: getValidationForNumberField(),
    untaxedPortionsOfPensions: getValidationForNumberField(),
    taxableCollegeOrScholarshipAid: getValidationForNumberField(),
    educationCredits: getValidationForNumberField(),
    USIncomeTax: getValidationForNumberField(),
    childSupportAidRecieved: getValidationForNumberField(),
    savingsAndCheckings: getValidationForNumberField(),
    investmentsWorth: getValidationForNumberField(),
    businessesValue: getValidationForNumberField(),
  })
);
