export const DEFAULT_FORM_VALUES = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  email: "",
  stateOfResidency: "",
  fafsaEligibility: "",
  isDependantStudent: "",
  parentEmail: "",
  householdPeople: null,
  isMarried: "",
  otherDependants: "",
  parentMaritalStatus: "",
  parentTaxFillingStatus: "",
  parentAdjustedGrossIncome: null,
  parentIncomeFromWork: null,
  parentDeductiblePaymentsToIRA: null,
  parentUntaxedPortionsOfIRA: null,
  parentUntaxedPortionsOfPensions: null,
  parentGrantOrScholarshipAid: null,
  parentEducationCredits: null,
  parentUSIncomeTax: null,
  childSupportAidRecievedByParent: null,
  parentSavingsAndCheckings: null,
  parentInvestmentsNetWorth: null,
  parentBusinessesValue: null,
  taxFillingStatus: "",
  AGI: null,
  incomeFromWork: null,
  paymentsToIRA: null,
  untaxedPortionsOfIRA: null,
  untaxedPortionsOfPensions: null,
  taxableCollegeOrScholarshipAid: null,
  educationCredits: null,
  USIncomeTax: null,
  childSupportAidRecieved: null,
  savingsAndCheckings: null,
  investmentsWorth: null,
  businessesValue: null,
};
