import React, { useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";

import ErrorText from "components/error-text/error-text";

import { getHelperText } from "components/helper-texts/helper-texts.helper";

import Icons from "assets";

const NumberInput = ({ question }) => {
  const { control, errors } = useFormContext();

  const { id, label, placeholder, required, helperKey, disabled } = question;

  const errorMessage = errors?.[id]?.message;

  const inputStyles = clsx(
    "flex transition ease-in text-base w-full mt-1 border cursor-default placeholder:text-text-placeholder p-2.5",
    "hover:border-border-hovered",
    "focus:outline-none focus:border-border-focused",
    "rounded-input-radius",
    {
      "!border-border-error": !!errorMessage,
      "border-border-default": !errorMessage,
      "!border-border-disabled": disabled,
    }
  );

  //prevent default behavior on wheel event
  useEffect(() => {
    const inputElement = document.getElementById(id);

    const handleWheelEvent = (e) => e.preventDefault();

    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheelEvent);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheelEvent);
      }
    };
  }, [id]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-col font-paragraph-family">
        <div className="flex flex-wrap">
          <label
            htmlFor={id}
            id={`${id}-label`}
            className="w-full text-base font-semibold text-text-label"
          >
            {label}
            {!disabled && required ? (
              <span>
                <Icons.RequiredIndicator customClass="inline-block align-top text-text-indicator-required w-[7px] h-[7px] ml-1 mt-1" />
              </span>
            ) : null}
          </label>
        </div>
        {!!helperKey && getHelperText(helperKey)}
        <div className="relative">
          <Controller
            control={control}
            name={id}
            render={({ field }) => (
              <input
                data-testid={id}
                aria-labelledby={`${id}-label`}
                id={id}
                className={inputStyles}
                type="number"
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                value={field.value || ""}
                min="0"
              />
            )}
          />
          {!disabled && !!errorMessage && (
            <ErrorText
              customClass="absolute left-0 top-[58px]"
              message={errorMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
