export const handleKeyDown = (event) => {
  const charCode = event.keyCode;
  const key = event.key;
  const ctrlKey = event.ctrlKey || event.metaKey;

  // Block Shift + number combinations
  if (event.shiftKey) {
    // Shift + 0, Shift + 1-9 -> "!", "@", "#", etc.
    if (charCode === 48 || (charCode >= 49 && charCode <= 57)) {
      event.preventDefault();
    }
  }

  // Allowed symbols and keys
  const allowedKeys = [
    "-",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
    "Numpad0",
    "Numpad1",
    "Numpad2",
    "Numpad3",
    "Numpad4",
    "Numpad5",
    "Numpad6",
    "Numpad7",
    "Numpad8",
    "Numpad9",
    "Control",
    "Meta",
    "Shift",
    "Alt",
  ];

  // Allowed numbers and some combinations
  if (
    allowedKeys.includes(key) ||
    (charCode >= 48 && charCode <= 57) || // Numbers 0-9 (main keyboard)
    (charCode >= 96 && charCode <= 105) || // Numpad Numbers 0-9
    (ctrlKey && (charCode === 65 || charCode === 67 || charCode === 86)) // Ctrl + A, Ctrl + C, Ctrl + V
  ) {
    return;
  }

  // Block all alphabetic letters (A-Z and a-z)
  if (
    (charCode >= 65 && charCode <= 90) ||
    (charCode >= 97 && charCode <= 122)
  ) {
    event.preventDefault();
    return;
  }

  // Block common symbols and special characters - without combinations
  const blockedSymbols = [
    "!",
    '"',
    "#",
    "$",
    "%",
    "&",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    ".",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "@",
    "[",
    "\\",
    "]",
    "^",
    "_",
    "`",
    "{",
    "|",
    "}",
    "~",
  ];

  if (blockedSymbols.includes(key)) {
    event.preventDefault();
    return;
  }
};

export const formatWithThousandSeparator = (value) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
