import { DEMO_DATA } from "constants/clients/demo";
import { SIENA_DATA } from "constants/clients/siena";
import { ACU_DATA } from "constants/clients/acu";
import { CATHOLIC_DATA } from "constants/clients/catholic";
import { ALFRED_DATA } from "constants/clients/alfred";
import { DAYTON_DATA } from "constants/clients/dayton";
import { REGIS_DATA } from "constants/clients/regis";
import { MORNINGSIDE_DATA } from "constants/clients/morningside";
import { ROOSEVELT_DATA } from "constants/clients/roosevelt";
import { GUSTAVUS_DATA } from "constants/clients/gustavus";
import { MISSOURI_DATA } from "constants/clients/missouristate";
import { PACE_DATA } from "constants/clients/pace";
import { BUTLER_DATA } from "constants/clients/butler";
import { BELLARMINE_DATA } from "constants/clients/bellarmine";

export const COLLEGES = [
  {
    data: DEMO_DATA,
    currentlyActive: true,
  },
  {
    data: SIENA_DATA,
  },
  {
    data: ACU_DATA,
  },
  {
    data: CATHOLIC_DATA,
  },
  {
    data: ALFRED_DATA,
  },
  {
    data: DAYTON_DATA,
  },
  {
    data: REGIS_DATA,
  },
  {
    data: MORNINGSIDE_DATA,
  },
  {
    data: ROOSEVELT_DATA,
  },
  { data: GUSTAVUS_DATA },
  { data: MISSOURI_DATA },
  { data: PACE_DATA },
  { data: BUTLER_DATA },
  { data: BELLARMINE_DATA },
];
